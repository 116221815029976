import React, { useEffect, useState } from 'react'
import style from './index.module.less'
import useGa from '@/hooks/useGa'
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from 'react-intl'

const RedBuyBtn = ({ cate }) => {
    const ifPc = useBigScreen(768)
    const intl = useIntl()
    const defaultStyle = {
        width: "160px",
        height: '160px',
        borderRadius: '100% 0 0 0',
        right: 0,
        bottom: 0,
        fontSize: "32px"
    }
    const defaultStyle_mobile = {
        width: "120px",
        height: '120px',
        borderRadius: '100% 0 0 0',
        right: 0,
        bottom: 0,
        fontSize: "24px"
    }
    const defaultSpanStyle = {
        bottom: "40px",
        right: "40px"
    }
    const defaultSpanStyle_mobile = {
        bottom: "30px",
        right: "30px"
    }
    const scrollStyle = {
        width: '80px',
        height: '80px',
        borderRadius: '50%',
        right: '20px',
        bottom: '20px',
        fontSize: "16px"
    }
    const scrollSpanStyle = {
        position: "relative"
    }
    const [btnStyle, setBtnStyle] = useState(defaultStyle)
    const [btnTextStyle, setBtnTextStyle] = useState(defaultSpanStyle)
    const addSensor = () => {
        if(cate=='jp_newlife'){
            typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
                custom: {
                    module_id: 20059,
                }
            });
        }else{
            typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
                custom: {
                    module_id: 10005,
                    products_name:cate
                }
            });
        }
      
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

    }, [ifPc])
    useEffect(() => {
        if (ifPc) {
            setBtnStyle(defaultStyle)
            setBtnTextStyle(defaultSpanStyle)
        } else {
            setBtnStyle(defaultStyle_mobile)
            setBtnTextStyle(defaultSpanStyle_mobile)

        }
    }, [ifPc])

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 20) {
            setBtnStyle(scrollStyle)
            setBtnTextStyle(scrollSpanStyle)
        } else {
            if (ifPc) {
                setBtnStyle(defaultStyle)
                setBtnTextStyle(defaultSpanStyle)
            }else{
                setBtnStyle(defaultStyle_mobile)
                setBtnTextStyle(defaultSpanStyle_mobile)
            }

        }
    }
    const link=(cate=='jp_newlife')?'https://jp.shop.xreal.com/':'#pro-buy'
    return (
        <a className={style.btn} style={btnStyle} href={link} onClick={() => { useGa(cate, `${cate.toLowerCase()}_buy`, 'Click') ,addSensor()}}>
            <span style={btnTextStyle}>{intl.locale=='ja'?'購入':'Buy'}</span>

        </a>
    )
}

export default RedBuyBtn